import React from 'react';
const Footer = () => {
  return(
    <footer>
      <div className="wrapper">
        <p>Miko Sramek © 2020</p>
      </div>
    </footer>
  )
}
export default Footer;